
import './about.css';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import EastIcon from '@mui/icons-material/East';

import React, { useEffect, useRef, useState } from 'react';




const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return (
    <div className='about-section'>
      <div className='section-title'>
        <h1>Sobre</h1>
        <h4>Quem somos</h4>
      </div>

      <div className='rec-container'>
        <div ref={divRef} className={`rec1 ${isVisible ? 'animate delay1' : ''}`}>
          {/* Content */}
          <LightbulbIcon/>
          <p>Inovação</p>
        </div>
        <div ref={divRef} className={`rec1 ${isVisible ? 'animate delay2' : ''}`}>
          {/* Content */}
          <PersonIcon/>
          <p>Exelência</p>
        </div>
        <div ref={divRef} className={`rec1 ${isVisible ? 'animate delay3' : ''}`}>
          {/* Content */}
          <DescriptionIcon/>
          <p>Gestão</p>
        </div>
      </div>

      <div className='info-container'>
        <p>
        Somos iniciativa dedicada a desenvolver sites e aplicativos personalizados para empresas de todos os tamanhos.
        Com uma visão clara e um plano estratégico para transformar ideias em soluções digitais inovadoras e funcionais.
        Buscando proporcionar uma presença online robusta e eficaz para nossos clientes, levando-os a se destacarem em um mercado cada vez mais competitivo.
        </p>
      </div>

    </div>
  );
};

export default About;
