import { useGLTF } from '@react-three/drei';
import { useState, useEffect } from 'react';
import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';

const canvasStyle = {
  height: '100vh',
  width: '90vw',
};

const Model = ({ rotation, position }) => {
  const { scene } = useGLTF('/assets/laptop/scene.gltf');
  return (
    <primitive
      object={scene}
      scale={[0.045, 0.045, 0.045]} // Adjusted scale for 150% bigger size
      rotation={rotation}
      position={position}
    />
  );
};

const Laptop = () => {
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [y, setY] = useState(0);
  const [smartphoneProps, setSmartphoneProps] = useState({ position: [-1.5, 0, 0.5], scale: [1, 1, 1] });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 500) {
        setSmartphoneProps({ position: [0.5, 0, 0.5], scale: [0.1, 0.1, 0.1] });
      } else if (width < 900) {
        setSmartphoneProps({ position: [1.5, 0, 0.5], scale: [0.8, 0.8, 0.8] });
      } else {
        setSmartphoneProps({ position: [1.5, 0, 0.5], scale: [1, 1, 1] });
      }
    };

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const newRotation = (scrollY / window.innerHeight) * Math.PI / 2;
      // setRotation([newRotation, newRotation, -newRotation]);
      // setRotation([0, 0, -newRotation/2]);

      if ((scrollY < 1600) && (scrollY > 0)) {
        setY(-scrollY / 600);
      } else {
        setY(scrollY / 600);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={canvasStyle}>
      <Canvas shadows camera={{ position: [0, 2, 5], fov: 60 }}>
        <ambientLight intensity={0.5} />
        <spotLight
          position={[10, 10, 10]}
          angle={0.15}
          penumbra={1}
          intensity={1}
          castShadow
        />
        <Suspense fallback={null}>
          <Model rotation={rotation} position={[y, smartphoneProps.position[2], smartphoneProps.position[1]]} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default Laptop;
