import React, { useState } from 'react';
import './contact.css';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EastIcon from '@mui/icons-material/East';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.phone) {
      errors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = 'Phone number is invalid';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!formData.message) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      console.log('Form submitted successfully:', formData);
      // Here, you can handle the form submission, such as sending the data to a server
      // For now, we'll just reset the form
      setFormData({
        name: '',
        phone: '',
        email: '',
        message: '',
      });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="contact-section">
      <div className='section-title'>
        <h1>Contato</h1>

      </div>
      <div className="contact-container">
      
        <div className="contact-medias">
          <div className='contact-card'>
            <LinkedInIcon style={{ fontSize: 40 }} />
            <h3 className="card-title">LinkedIn</h3>
            <p className="project-link">
              Enviar mensagem
              <EastIcon />
            </p>
          </div>
          <div className='contact-card'>
            <WhatsAppIcon style={{ fontSize: 40 }} />
            <h3 className="card-title">WhatsApp</h3>
            <p className="project-link">
              Enviar mensagem
              <EastIcon />
            </p>
          </div>
        </div>

        <form className="contact-form" onSubmit={handleSubmit}>
          <h4>Deixe uma mensagem</h4>

          {/* NAME */}
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="nome"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>

          {/* PHONE */}
          <div className="form-group">
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="telefone"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>

          {/* EMAIL */}
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          {/* TEXT AREA */}
          <div className="form-group">
            <textarea
              id="message"
              name="message"
              placeholder="Mensagem"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <span className="error">{errors.message}</span>}
          </div>

          <button type="submit">Enviar mensagem</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
