// App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './screens/nav/nav.js';
import Home from './screens/home/home.js';
import Projects from './screens/Projects/projects.js';
import Contact from './screens/Contact/contact.js';
import About from './screens/About/about.js';



const App = () => {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </Router>
  );
};

export default App;
