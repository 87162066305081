import React from 'react';
import './projects.css';
import Laptop from '../../3DObjects/Laptop.js';
import EastIcon from '@mui/icons-material/East';

const proj = [
  {
    title: 'Locadora de Carros',
    link: 'https://project-one.com',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'Restaurante',
    link: 'https://project-two.com',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'Locadora de Carros',
    link: 'https://project-one.com',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'Restaurante',
    link: 'https://project-two.com',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'Locadora de Carros',
    link: 'https://project-one.com',
    image: 'https://via.placeholder.com/150'
  },
  {
    title: 'Restaurante',
    link: 'https://project-two.com',
    image: 'https://via.placeholder.com/150'
  },
  // Add more projects here
];

const ProjectCard = ({ title, link, image }) => {
  return (
    <div className="project-card">
      <img src={image} alt={title} className="project-image" />
      <div className="project-content">
        <h3 className="project-title">{title}</h3>
        <a href={link} target="_blank" rel="noopener noreferrer" className="project-link">
          Ver mais
          <EastIcon />
        </a>
      </div>
    </div>
  );
};

const Projects = () => {
  return (
    <div className='project-section'>
      <div className='project-container'>
        <h1>PROJECTS</h1>
        <div className='lap-container'>
          <Laptop />
        </div>
        
        <div className='cards-container'>
          {proj.map((project, index) => (
            <ProjectCard
              key={index}
              title={project.title}
              link={project.link}
              image={project.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
