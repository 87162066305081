import React, { useState, useEffect, useRef } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { Link, Element } from 'react-scroll';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Systems from '../../3DObjects/Systems.js';





import Services from '../Services/services.js';
import About from '../About/about.js';
import Projects from '../Projects/projects.js';
import ContactForm from '../Contact/contact.js';
import Footer from '../Footer/footer.js';



import './home.css';

const data = [
  { id: '1', title: 'O Brasil possui 215 milhões (desktop, notebook e tablet) em uso, atingindo um computador por habitante', author: 'Fundação Getulio Vargas' },
  { id: '2', title: 'No Brasil os celulares smartphones sozinhos somam 249 milhões, um número 20% maior do que o total da população.', author: 'VEJA' },
  { id: '3', title: 'Item 3', author: 'Author 3' },
  // ...more items
];

const BasicList = () => {
  const listRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.animation = `fall 0.6s forwards`;
            entry.target.style.animationDelay = `${entry.target.dataset.index * 0.2}s`;
          } else {
            entry.target.style.animation = 'none';
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'translateY(-50px)';
          }
        });
      },
      { threshold: 0.1 }
    );

    listRef.current.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      listRef.current.forEach((el) => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  return (
    <div>
      {data.map((item, index) => (
        <div
          key={item.id}
          className='listStyle'
          data-index={index}
          ref={(el) => (listRef.current[index] = el)}
        >
          <div className='author'>{item.author}</div>
          <div className='title'>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

function TypingEffect() {
  return (
    <div>
      <Typewriter
        words={['Digital?', 'crescendo no Digital?']}
        loop={5}
        cursor
        cursorStyle='_'
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={1000}
      />
    </div>
  );
};

function scrollBy() {
  window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
}

const Home = () => {
  const homeHeaderRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > window.innerHeight) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrolled) {
      homeHeaderRef.current.style.animation = 'slideLeftFade 1s forwards';
    } else {
      homeHeaderRef.current.style.animation = 'none';
    }
  }, [scrolled]);

  return (
    <div>
      {/* BEGINNING OF LANDING PAGE */}
      <div name='home' className='homeHeader' ref={homeHeaderRef}>
        <div className='backSys'>
          <div className='placement'>
            <Systems />
          </div>
        </div>
        <div className='frontTex'>
          <div></div>
          <div className='mainTitle'>
            <h3>Emprez</h3>
            <h1>Já imaginou o seu negócio,</h1>
            <h1>
              <TypingEffect />
            </h1>
          </div>
          <div className='phoneBtn'>
            <button className='moreBtn' onClick={scrollBy}><ArrowDownwardIcon /></button>
          </div>
        </div>
        <div className='works'>
          <BasicList />
        </div>
      </div>

      {/* ABOUT SECTION */}
      <div name='about'>
        <About />
      </div>

      {/* SERVICES SECTION */}
      <div name='services'>
        <Services />
      </div>

      {/* PROJECT SECTION */}
      <div name='projects'>
        <Projects />
      </div>

      {/* CONTACT SECTION */}
      <div name='contact'>
        <ContactForm />
      </div>

      <div>
        <Footer />
      </div>




    </div>
  );
};

export default Home;
