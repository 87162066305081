import { useGLTF } from '@react-three/drei';
import { useState, useEffect } from 'react';
import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
// import { OrbitControls } from '@react-three/drei';

const canvasStyle = {
  height: '250vh',
  width: '90vw',
};

const Monitor = ({ position, scale }) => {
  const { scene } = useGLTF('/assets/monitor/scene.gltf');
  return (
    <primitive
      object={scene}
      scale={scale}
      position={position}
    />
  );
};

const Keyboard = ({ rotation, position, scale }) => {
  const { scene } = useGLTF('/assets/keyboard/scene.gltf');
  return (
    <primitive
      object={scene}
      scale={scale}
      rotation={rotation}
      position={position}
    />
  );
};

const Smartphone = ({ position, scale, rotation }) => {
  const { scene } = useGLTF('/assets/smartphone/scene.gltf');
  return (
    <primitive
      object={scene}
      position={position}
      scale={scale}
      rotation={rotation}
    />
  );
};

const Systems = () => {
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [scale, setScale] = useState([0.5, 0.5, 0.5]);
  const [rotPhone, setRotPhone] = useState([0, 0, 0]);
  const [y, setY] = useState(0);
  const [smartphoneProps, setSmartphoneProps] = useState({ position: [1.5, 0, -0.5], scale: [0.5, 0.5, 0.5] });
  const [keyboardProps, setKeyboardProps] = useState({ position: [0, -0.4, 0], scale: [5, 5, 5] });
  const [monitorProps, setMonitorProps] = useState({ position: [0, 0, 0], scale: [0.5, 0.5, 0.5] });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 500) {
        // Adjust for small screens
        setSmartphoneProps({ position: [0.5, 3, -0.5], scale: [0.1, 0.1, 0.1] });
        setKeyboardProps({ position: [0, -0.15, 0], scale: [2, 2, 2] });
        setMonitorProps({ position: [0, 0, 0], scale: [0.2, 0.2, 0.2] });
      } else if (width < 900) {
        // Adjust for medium screens
        setSmartphoneProps({ position: [1.5, 0, -0.5], scale: [0.4, 0.4, 0.4] });
        setKeyboardProps({ position: [0, -0.35, 0], scale: [4, 4, 4] });
        setMonitorProps({ position: [0, 0, 0], scale: [0.45, 0.45, 0.45] });
      } else {
        // Adjust for large screens
        setSmartphoneProps({ position: [1.5, 0, -0.5], scale: [0.5, 0.5, 0.5] });
        setKeyboardProps({ position: [0, -0.4, 0], scale: [5, 5, 5] });
        setMonitorProps({ position: [0, 0, 0], scale: [0.5, 0.5, 0.5] });
      }
    };

    const handleScroll = () => {
      const width = window.innerWidth;
      const scrollY = window.scrollY;
      const newRotation = (scrollY / window.innerHeight) * Math.PI / 2;
      setRotation([newRotation, 0, 0]);

      if (scrollY < 641) {
        setY(-1 * (scrollY / 200));
      }


      if (y < 100) {
        setRotPhone([0, (newRotation / 3), 0]);

      } else {
        setRotPhone([0, (-newRotation / 3), 0]);
      }
      console.log(scrollY);
    };

    handleResize(); // Set initial sizes based on current screen width
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [y]);

  return (
    <div style={canvasStyle}>
      <Canvas shadows camera={{ position: [0, 2, 5], fov: 60 }}>
        <ambientLight intensity={0.5} />
        <spotLight
          position={[1, 1, 1]}
          angle={0.15}
          penumbra={1}
          intensity={1}
          castShadow
        />
        <Suspense fallback={null}>
          <Keyboard {...keyboardProps} rotation={rotation} />
          <Monitor {...monitorProps} />
          <Smartphone {...smartphoneProps} rotation={rotPhone} position={[smartphoneProps.position[0], y, smartphoneProps.position[2]]} />
        </Suspense>

      </Canvas>
    </div>
  );
};

export default Systems;
