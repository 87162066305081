import React from 'react';
import './services.css';

import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import DrawIcon from '@mui/icons-material/Draw';
import EastIcon from '@mui/icons-material/East';

const Services = () => (
  <div className='services-section'>
    <div className='section-title'>
      <h1>Serviços</h1>
      <h4>Nós especializamos em</h4>
    </div>
    <div className='card-container'>

      <a href={''} target="_blank" rel="noopener noreferrer">
        <div className='card'>
          <CodeIcon className='icon'/>
          <h3 className="card-title">Marketing<br/>Digital</h3>
          <p className="project-link">
            Ver mais
            <EastIcon/>
          </p>
        </div>
      </a>

      <a href={''} target="_blank" rel="noopener noreferrer">
        <div className='card'>
          <WebIcon className='icon'/>
          <h3 className="card-title">Desenvolvimento<br/>Web</h3>
          <p className="project-link">
            Ver mais
            <EastIcon />
          </p>
        </div>
      </a>

      <a href={''} target="_blank" rel="noopener noreferrer">
        <div className='card'>
          <DrawIcon className='icon'/>
          <h3 className="card-title">Identidade<br/>Visual</h3>
          <p className="project-link">
            Ver mais
            <EastIcon />
          </p>
        </div>
      </a>

    </div>
  </div>
);

export default Services;
