
import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/js/all.min.js';
import React, { useRef, useState, useEffect } from 'react';
import './nav.css';
import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Nav() {
  // State to manage the visibility of the div

  const [showDiv, setShowDiv] = useState(true);
  const [windowS, setWindowS] = useState(true);


  const divRef = useRef(null);


  useEffect(() => {
    const handleResize = () => {
      // Check the window width to determine whether to show or hide navigation
      if (window.innerWidth <= 768) {
        setShowDiv(false); // Hide navigation for smaller screens
        setWindowS(true);
      } else {
        setShowDiv(true); // Show navigation for larger screens
        setWindowS(false);
      }
    };

    // Call handleResize initially and add event listener
    handleResize();

    window.addEventListener('resize', handleResize);

    // Cleanup: Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Function to toggle the visibility of the div
  const toggleDiv = () => {
    if(windowS) {
      setShowDiv(!showDiv)
    }


  };


  return (
    <div className="nav-bar">

      <Link to="/" style={{ textDecoration: 'none' }}>
        <h1>EMPREZ</h1>
      </Link>

      <div className="direction">
        <button className="bar" onClick={toggleDiv}>
          { showDiv ?
            <CloseIcon/>
            :
            <MenuIcon/>
          }

        </button>



        <div ref={divRef} className="nav-items" style={{visibility: showDiv? 'visible' : 'hidden' }}>


          <Link to="home" style={{ textDecoration: 'none' }} onClick={toggleDiv}>
            <div className="vitems">
              {(showDiv && windowS) ? (
                <HomeIcon/>
              ) : null }

              <p>Home</p>
            </div>
          </Link>


          <Link to="about" style={{ textDecoration: 'none' }} onClick={toggleDiv}>
            <div className="vitems">
              {(showDiv && windowS) ? (
                <AccountCircleIcon/>
              ) : null }

              <p>Sobre</p>
            </div>
          </Link>

          <Link to="services" style={{ textDecoration: 'none' }} onClick={toggleDiv}>
            <div className="vitems">
              {(showDiv && windowS) ? (
                <CalendarMonthIcon/>
              ) : null }

              <p>Serviços</p>
            </div>
          </Link>



          <Link to="projects" style={{ textDecoration: 'none' }} onClick={toggleDiv}>
            <div className="vitems">
              {(showDiv && windowS) ? (
                <CalendarMonthIcon/>
              ) : null }

              <p>Projetos</p>
            </div>
          </Link>



          <Link to="contact" style={{ textDecoration: 'none' }} onClick={toggleDiv}>
            <div className="vitems">
              {(showDiv && windowS) ? (
                <NoteAltIcon/>
              ) : null }

              <p>Contato</p>
            </div>
          </Link>

        </div>
      </div>


    </div>
  );
}

export default Nav;
